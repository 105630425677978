import { FC } from 'react';
import { useServiceRunsContext } from "../../../app/modules/ServiceRuns/context/ServiceRuns-context";

export interface SubmitButtonInterface {
  form: {
    handleSubmit: () => {},
    values: {},
    dirty: boolean
  },
  state: {},
  label: string,
  isLoading: boolean,
  showInEdit: boolean,
  showInNew: boolean
}


export const SubmitButton: FC<SubmitButtonInterface> = ({
  label,
  state,
  isLoading = false,
  form: { handleSubmit, dirty },
  showInEdit,
  showInNew
}) => {

  const { editTable, newRow } = useServiceRunsContext();

  return (
    <>
      {((state === null && !showInEdit && !showInNew) ||
        (showInEdit && editTable) ||
        (showInNew && newRow)) &&
        <button
          type='button'
          className='btn btn-color-success btn-success text-white btn-sm me-5'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
          onClick={() => handleSubmit()}
          disabled={isLoading || !dirty ? true : false}
        >
          <div className="d-flex align-items-center">
            <span>{label}</span>
            {isLoading && (
              <span className="spinner-border spinner-border-sm text-light ms-2"></span>
            )}
          </div>
        </button>
      }
    </>
  );
}